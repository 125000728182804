<template>
  <transition name="fade-in-up">
    <router-view></router-view>
  </transition>
</template>

<style lang="scss" scoped>
@import '~bootstrap-vue/dist/bootstrap-vue.css';
</style>

<script>
import { SET_MENU } from '@/core/services/store/menu.module';
import localData from '../../../utils/saveDataToLocal';
import { DEFAULT_TITLE } from '../../../utils/constants';
export default {
  components: {},
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || DEFAULT_TITLE;
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_MENU, [
      {
        name: 'Hóa đơn bán lẻ',
        icon: 'menu-icon far fa-file-alt',
        route: '/bills',
        show: this.checkPermission('BILL_VIEW'),
      },
      {
        name: 'Hóa đơn bán buôn',
        icon: 'menu-icon fas fa-file-alt',
        route: '/wholesaleInvoices',
        show: this.checkPermission('BILL_VIEW'),
      },
      {
        name: 'Trả hàng',
        icon: 'menu-icon flaticon-reply',
        route: '/returnBills',
        show: this.checkPermission('BILL_VIEW'),
      },
      {
        name: 'Hóa đơn đã xóa',
        icon: 'menu-icon far fa-file-alt',
        route: '/bill-deleted',
        show: this.checkPermission('BILL_VIEW'),
      },
      {
        name: 'Lịch sử',
        icon: 'menu-icon fas fa-history',
        route: '/bill-history',
        show: this.checkPermission('BILL_VIEW'),
      },
    ]);
  },
  methods: {
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>
